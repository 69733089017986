import React from 'react'
import Navbar from '../Header/Navbar'
import Footer from '../Footer/Footer'

function TermCondition() {
    return (
        <>
            <Navbar />
            <br />
            <br />
            <br />
            <br />

            <div className="container mt-4 mb-4">
                <h2>Term & Conditions</h2>
                <p style={{ fontSize: "18px", textAlign: "justify" }}>The following Terms and Conditions govern your use of the talent-destination.com website in conjunction with our PRIVACY POLICY. By accessing this website, you acknowledge your acceptance and agreement to adhere to these Terms and Conditions, as they may be updated periodically. If you do not agree with these terms, kindly refrain from using the website.
                </p>
                <p style={{ fontSize: "18px", textAlign: "justify" }}>
                    Additionally, our recruitment services and business relationship with you are subject to our standard Terms and Conditions for clients and candidates, which can be provided by our specialist consultants during discussions about your recruitment needs.
                </p>
                <p style={{ fontSize: "18px", textAlign: "justify" }}>
                    We reserve the right to modify these Terms and Conditions at our discretion and without prior notice. We recommend checking this page regularly to stay informed about any changes.
                </p>


                <p><strong>Use of Website</strong></p>
                <p style={{ fontSize: "18px", textAlign: "justify" }}>The purpose of this website is to provide you with our services and general information. You must not breach any term of our Acceptable Use Policy set out below.
                    .
                </p>
                <p><strong>Links to or from other Sites</strong></p>
                <p style={{ fontSize: "18px", textAlign: "justify" }}>
                    This site includes links to external websites, and there may be numerous automatic links to other sites that could be of interest to you. Talent Destination does not take responsibility for the content of these sites, and it cannot be assumed that Talent Destination has reviewed or approved of such sites or their content. Additionally, we do not guarantee the functionality or currency of the links to these sites.

                </p>

                <p><strong>Disclaimer</strong></p>
                <p style={{ fontSize: "18px", textAlign: "justify" }}>
                    The information and material presented on this website are intended as a general guide only. Despite using reasonable care in compiling and presenting this information, Talent Destination makes no representation or warranty regarding the accuracy, currency, completeness, adequacy, suitability, or operation of this website, the services provided through it, or the information/material it contains.
                </p>
                <p style={{ fontSize: "18px", textAlign: "justify" }}>
                    To the maximum extent permitted by law, Talent Destination shall not be held responsible or liable for:
                </p>

                <ol type="a">
                    <li style={{ fontSize: "18px", textAlign: "justify" }}> any loss, liability or damage suffered or incurred arising out of or in connection with any access to or use of this website or any of its content;
                    </li>
                    <li style={{ fontSize: "18px", textAlign: "justify" }}>
                        Any reliance on, or decision made based on, information or material shown on or omitted from this website.
                    </li>
                    <li style={{ fontSize: "18px", textAlign: "justify" }}>
                        Any representation or otherwise in respect of the existence or availability of any job, vacancy, assignment or other engagement or appointment advertised on this website;
                    </li>
                    <li style={{ fontSize: "18px", textAlign: "justify" }}>
                        any representation or otherwise that any employer or client will ask for a candidate's information, ask to interview or hire a candidate, or that any candidates will be available or will meet the needs of any employer or client;
                    </li>

                </ol>

               
                <p><strong>Log Data</strong></p>
                <p> I want to inform you that whenever you use my Service, in a case of
                    an error in the app I collect data and information (through third party products) on your phone
                    called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address,
                    device name, operating system version, the configuration of the app when utilizing my Service,
                    the time and date of your use of the Service, and other statistics.
                </p>
                <p><strong>Cookies</strong></p>
                <p>Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers.
                    These are sent to your browser from the websites that you visit and are stored on your device's internal
                    memory.
                </p>
                <p>This Service does not use these “cookies” explicitly. However, the app may use third party code and
                    libraries that use “cookies” to collect information and improve their services. You have the option to
                    either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose
                    to refuse our cookies, you may not be able to use some portions of this Service.
                </p>
                <p><strong>Service Providers</strong></p>
                <p> I may employ third-party companies and individuals due to the following reasons:</p>
                <ul>
                    <li>To facilitate our Service;</li>
                    <li>To provide the Service on our behalf;</li>
                    <li>To perform Service-related services; or</li>
                    <li>To assist us in analyzing how our Service is used.</li>
                </ul>
                <p> I want to inform users of this Service that these third parties have access to
                    your Personal Information. The reason is to perform the tasks assigned to them on our behalf. However,
                    they are obligated not to disclose or use the information for any other purpose.
                </p>
                <p><strong>Security</strong></p>
                <p> I value your trust in providing us your Personal Information, thus we are striving
                    to use commercially acceptable means of protecting it. But remember that no method of transmission over
                    the internet, or method of electronic storage is 100% secure and reliable, and I cannot guarantee
                    its absolute security.
                </p>
                <p><strong>Links to Other Sites</strong></p>
                <p>This Service may contain links to other sites. If you click on a third-party link, you will be directed
                    to that site. Note that these external sites are not operated by me. Therefore, I strongly
                    advise you to review the Privacy Policy of these websites. I have no control over
                    and assume no responsibility for the content, privacy policies, or practices of any third-party sites
                    or services.
                </p>
                <p><strong>Children’s Privacy</strong></p>
                <p>These Services do not address anyone under the age of 13. I do not knowingly collect
                    personally identifiable information from children under 13. In the case I discover that a child
                    under 13 has provided me with personal information, I immediately delete this from
                    our servers. If you are a parent or guardian and you are aware that your child has provided us with personal
                    information, please contact me so that I will be able to do necessary actions.
                </p>
                <p><strong>Changes to This Privacy Policy</strong></p>
                <p> I may update our Privacy Policy from time to time. Thus, you are advised to review
                    this page periodically for any changes. I will notify you of any changes by posting
                    the new Privacy Policy on this page. These changes are effective immediately after they are posted on
                    this page.
                </p>
                <p><strong>Contact Us</strong></p>
                <p>If you have any questions or suggestions about my Privacy Policy, do not hesitate to contact
                    me.
                </p>
             
            </div>
            <Footer />
        </>
    )
}

export default TermCondition