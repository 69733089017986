import React from 'react'
import {useParams} from "react-router-dom";
import service1 from '../Images/service_banner.jpg'
import service12 from '../Images/Executive Services.png'
import Navbar from '../Header/Navbar'
import Footer from '../Footer/Footer'
import Career from './Career';
import Blogs from "./Blogs"
import NewRooms from "./Blogs"


function Service() {
    const {id} = useParams()
    return (
        <>
         {id==="blogs"?<Blogs/>:
          id==="newsrooms"?<NewRooms/>:
          id==="career"?<Career/>:""}
        </>
    )
}

export default Service