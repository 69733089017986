import React from 'react'
import Aboutimage from '../Images/right-img-1.png'
import contactus from '../Images/AboutBanner.png'
import Atul from '../Images/Untitled design (5).jpg'
// import Urmi from '../Images/Untitled design (6).jpg'
import Navbar from '../Header/Navbar'
import Footer from '../Footer/Footer'
import WhyChooseus from '../Pages/WhyChooseus'

function About() {
    return (
        <>
            <Navbar />
            <div
                className="untree_co-hero1 overlay"
                style={{
                    backgroundImage:

                        `url(${contactus})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    height: "380px"




                }}
            >
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-12">
                            <div className="row justify-content-center ">
                                <div className="col-lg-6 text-center ">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="services-section">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-lg-4 mb-5 mb-lg-0">
                            <div
                                className="section-title mb-3"

                            >
                                <h2 className="line-bottom mb-4">About Us</h2>
                            </div>
                            <p
                                style={{ textAlign: "justify", fontFamily: "poppins", fontSize: "18px" }}

                            >
                                The company started modestly to evolve into a premier executive search &
                                HR solution provider for businesses of all sizes across diverse industries
                                and functions.
                                {" "}
                            </p>
                            <p
                                style={{ textAlign: "justify", fontFamily: "poppins", fontSize: "18px" }}

                            >
                                Our approach involves merging a genuine passion of individuals with
                                strategic-driven processes, aiming to assist organizations in seamlessly
                                transforming candidates into employee..
                            </p>
                            <p
                                style={{ textAlign: "justify", fontFamily: "poppins", fontSize: "18px" }}

                            >
                                As a premier talent hiring and Human Resource solution provider, we are
                                dedicated to transforming businesses through strategic human capital
                                management.
                            </p>




                        </div>
                        <div className="col-lg-6" data-aos="fade-up" data-aos-delay={0}>
                            <figure className="img-wrap-2">
                                <img

                                    src={Aboutimage}
                                    alt="Image"
                                    className="img-fluid"
                                />
                                <div className="dotted" />
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
            <div className="untree_co-section">
                <div className="container">
                    <div className="row justify-content-center mb-5">
                        <div
                            className="col-lg-7 text-center"

                        >
                            <h2 className="line-bottom text-center mb-4">How we work </h2>
                            <p style={{ textAlign: "justify", fontSize: "20px" }}>
                                We employ our innovative 3 – Screen Model in Leadership Hiring to
                                help clients reduce their hiring time by closing the positions in
                                the lowest TAT possible. Our idea is simple – to bring together the
                                most like-minded people, create a small community of believers and
                                generate value for our stakeholders through our indefatigable
                                efforts!
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div
                            className="col-6 col-sm-6 col-md-6 col-lg-4"

                        >
                            <div className="feature">
                                <span className="uil uil-music" />
                                <h3>Talent Mapping</h3>
                                <p style={{ textAlign: "justify" }}>
                                    We locate the best individual talent using cutting-edge technology
                                    and social media from a wellresearched talent landscape.
                                </p>
                            </div>
                        </div>
                        <div
                            className="col-6 col-sm-6 col-md-6 col-lg-4"

                        >
                            <div className="feature">
                                <span className="uil uil-calculator-alt" />
                                <h3>Talent Fitment</h3>
                                <p style={{ textAlign: "justify" }}>
                                    We ensure that the talents fit the job, through our intelligent
                                    scan of interest, aptitude, ability, and personality.
                                </p>
                            </div>
                        </div>
                        <div
                            className="col-6 col-sm-6 col-md-6 col-lg-4"

                        >
                            <div className="feature">
                                <span className="uil uil-book-open" />
                                <h3>Talent Assessment</h3>
                                <p style={{ textAlign: "justify" }}>
                                    Our team of seasoned psychologists and OB practitioners help
                                    create, design, and schedule assessments.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="untree_co-section bg-light">
                <div className="container">
                    <div className="row justify-content-center mb-1">
                        <div
                            className="col-lg-7 text-center"

                        >
                            <h2 className="line-bottom text-center mb-4">Our Leadership Member</h2>

                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div
                            className="col-6 col-sm-6 col-md-12 mb-4 mb-lg-0 col-lg-6"

                        >
                            <div className="staff text-center" style={{ border: "1px solid red", borderRadius: "30px" }}>

                                <div className="staff-body">
                                    <h3 className="staff-name">Ashutosh Dubey</h3>
                                    <span className="d-block position">Director</span>
                                    <p style={{ textAlign: "justify", fontFamily: "poppins", fontSize: "20.2px" }}>
                                        Ashutosh is a seasoned Talent Acquisition Professional with a unique corporate and agency recruitment experience blend. He excels in building and managing high-performing teams that drive business results in the Americas and APAC. With a passion for excellence and innovation, Ashutosh transforms Talent Acquisition processes through automation and data-driven strategies. His expertise spans full-lifecycle recruitment, client management, vendor partnerships, and employer branding. Ashutosh is dedicated to identifying and executing strategic staffing initiatives that propel business growth.
                                    </p>

                                </div>
                            </div>
                        </div>

                        <div
                            className="col-6 col-sm-6 col-md-12 mb-4 mb-lg-0 col-lg-6"

                        >
                            <div className="staff text-center" style={{ border: "1px solid red", borderRadius: "30px" }}>

                                <div className="staff-body">
                                    <h3 className="staff-name">Vivek Mishra</h3>
                                    <span className="d-block position">Head of Operations & Delivery</span>
                                    <p style={{ textAlign: "justify", fontFamily: "poppins", fontSize: "17px" }}>
                                        Vivek Mishra is a seasoned leader with a unique blend of academic and professional credentials. Holding an MBA from NMIMS and a Law degree from the Royal College of Law, Vivek brings a decade of expertise in HR and Executive Search to Talent Destination.

                                        With over 10+ years of experience in renowned Executive Search Firms and Multinational Corporations, Vivek has honed his skills in strategic talent management, operational excellence, and business growth. As the Managing Director and Head of Operations at Talent Destination, Vivek oversees the entire operations and drives business growth.

                                        Under Vivek's leadership, Talent Destination is poised for continued success, leveraging his expertise to deliver exceptional talent solutions to clients and foster a culture of excellence within the organization.
                                    </p>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <WhyChooseus />
            <Footer />
        </>

    )
}

export default About