import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import axios from 'axios';
import "../CustomCss/Testimonials.css"


function Testimonials() {
  const [testimonials, setTestimonials] = useState([]);

  const testimonialUsers = async () => {
    try {
      const { data } = await axios.get('https://api.talent-destination.com/api/Admin/getAllTestimonial');
      setTestimonials(data.response);
    } catch (error) {
      console.error("Error fetching testimonials:", error);
    }
  }

  useEffect(() => {
    testimonialUsers();
  }, []);

  var settings = {
    infinite: true,
    autoplay: true,
    autoplaySpeed: 7000,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <>
      <div>
        <h2 className='text-center line-bottom '> Testimonials </h2>
        <h3 className='text-center '>Our constant endeavor is to delight our clients through service excellence</h3>
        <Slider {...settings}>
          {testimonials.map((item, index) => (
            <div key={index}>
              {/* Display multiple cards in each slide */}
              <div className="testimonial-card">
                <div className="testimonial">
                  <div className="pic">
                    <img src={`https://admin.talent-destination.com/TestimonalsImages/${item.filePath}`} alt={`Person ${index + 1}`} />
                  </div>
                  <p className="description">
                    {item.paragraph}
                  </p>
                  <h3 className="title">{item.name}-</h3>
                  <small className="post ml-1">{item.designation}</small>
                </div>
              </div>
              {/* Repeat the above structure for each card */}
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
}




export default Testimonials