import React from 'react'

function HowWeWork() {
  return (
    <div className="untree_co-section bg-light">
    <div className="container">
      <div className="row justify-content-center mb-5">
        <div
          className="col-lg-7 text-center"
          data-aos="fade-up"
          data-aos-delay={0}
        >
          <h2 className="line-bottom text-center mb-4">How we work</h2>
          <p style={{ textAlign: "justify",fontFamily:"poppins",fontSize:"20px" }}>
            We employ our innovative 3 – Screen Model in Leadership Hiring to help
            clients reduce their hiring time by closing the positions in the
            lowest TAT possible. Our idea is simple – to bring together the most
            like-minded people, create a small community of believers and generate
            value for our stakeholders through our indefatigable efforts!
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-sm-6 col-md-6 col-lg-4 mb-4 mb-lg-0">
          <div className="custom-media">
            <a href="#">
              <img
                style={{ height: 350, width: 400 }}
                src="https://media.istockphoto.com/id/531113849/photo/brainstorming-with-the-best.jpg?s=612x612&w=0&k=20&c=hiT_5SL8OOzHTvASIF4JTtzltDq9EzIIrfigit7RBPI="
                alt="Image"
                className="img-fluid"
              />
            </a>
            <div className="custom-media-body">
              <h3>Talent Mapping</h3>
              <p className="mb-4" style={{ textAlign: "justify",fontFamily:"poppins",fontSize:"17px" }}>
                We locate the best individual talent using cutting-edge technology
                and social media from a wellresearched talent landscape.
              </p>
              <div className="border-top d-flex justify-content-between pt-3 mt-3 align-items-center">
                <div>
                  <a href="#">Learn More</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-6 col-lg-4 mb-4 mb-lg-0">
          <div className="custom-media">
            <a href="#">
              <img
                style={{ height: 350, width: 400 }}
                src="https://media.istockphoto.com/id/1692077897/photo/vertical-image-of-human-resources-management-hiring-recruitment-and-teamwork-business-concept.jpg?s=612x612&w=0&k=20&c=kilNd3kAvHr0wfnj6fV0plAjn2WNeb-PhTGk0BXK9Iw="
                alt="Image"
                className="img-fluid"
              />
            </a>
            <div className="custom-media-body">
              <h3>Talent Fitment</h3>
              <p className="mb-4" style={{ textAlign: "justify",fontFamily:"poppins",fontSize:"17px" }}>
                We ensure that the talents fit the job, through our intelligent
                scan of interest, aptitude, ability, and personality.
              </p>
              <div className="border-top d-flex justify-content-between pt-3 mt-3 align-items-center">
                <div>
                  <a href="#">Learn More</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-6 col-lg-4 mb-4 mb-lg-0">
          <div className="custom-media">
            <a href="#">
              <img
                style={{ height: 350, width: 400 }}
                src="https://media.istockphoto.com/id/1436680248/photo/vertical-closeup-of-a-hand-holding-3d-rendered-human-icons-for-hr-management-and-recruitment.jpg?s=612x612&w=0&k=20&c=otAw0mJGjNKtcdHoWqbuYOHSTQtujQAzdjSeeuIESrU="
                alt="Image"
                className="img-fluid"
              />
            </a>
            <div className="custom-media-body">
              <h3>Talent Assessment</h3>
              <p className="mb-4" style={{ textAlign: "justify",fontFamily:"poppins",fontSize:"17px" }}>
                Our team of seasoned psychologists and OB practitioners help
                create, design, and schedule assessments.
              </p>
              <div className="border-top d-flex justify-content-between pt-3 mt-3 align-items-center">
                <div>
                  <a href="#">Learn More</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  )
}

export default HowWeWork